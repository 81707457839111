<template lang="pug">
svg(:class='`svg-${name}`'): use(:xlink:href='`#svg-${name}`')
</template>

<script>
  export default {
    props: {
      name: String,
    },
  };
</script>
